import React from 'react'
import { Stack, Main, PreFooter } from '@layout'
import { Box, Flex } from 'theme-ui'
import PageTitle from '@components/PageTitle'
import Pagination from '@components/Pagination'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import AuthorExpanded from '@widgets/AuthorExpanded'
import useSiteMetadata from '@helpers/useSiteMetadata'
import Categories from '@widgets/Categories'
import Tags from '@widgets/Tags'
import { useBlogCategories, useBlogTags } from '@helpers-blog'

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, `wrap`, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, `full`, `1/2`]
  }
}

const Collection = ({ data: { posts, collectionInfo } }) => {
  const { topic } = useSiteMetadata()
  const categories = useBlogCategories()
  const tags = useBlogTags()

  return (  
    <>
      <Seo title={collectionInfo.name} />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle header={`${topic} Franchise Experts`} totalCount={posts.totalCount} />
      </Stack>
      <Divider />
      <Stack>
        <Main>
          <AuthorExpanded author={collectionInfo} />
          <Divider />
          {posts.nodes && (
            <CardList
              nodes={posts.nodes}
              variant={['horizontal-md', 'vertical']}
              columns={[1, 2, 3, 3]}
            />
          )}
        </Main>
      </Stack>
      <Divider />
      <PreFooter>
        <Pagination {...posts.pageInfo} {...collectionInfo} />
      </PreFooter>
      <Divider space={4} />
      <Stack effectProps={{ effect: false }}>
        <Flex sx={styles.grid}>
          <Box sx={styles.column}>
            <Categories categories={categories} variant='vertical' />
          </Box>
          <Box sx={styles.column}>
            <Tags tags={tags} />
          </Box>
        </Flex>
      </Stack>
    </>
  )
}

export default Collection
